<template>
  <v-app>
  <v-container style="height: 200px;">
  </v-container>
    <v-icon style="font-size:50px">mdi-login</v-icon>
    <label class="form_header">Log in to ITDA</label>
    <v-container class="vcontainer">
      <v-layout align-center row wrap>
        <v-flex xs12>
            <div class="pa-10">
              <v-form ref="form" >
              <label class="form_description">Username</label>
                <v-text-field
                  class="form_contents"
                  v-model="form.username"
                  prepend-inner-icon="mdi-account"
                />
                <label class="form_description">Password</label>
                <v-text-field
                  class="form_contents"
                  v-model="form.password"
                  prepend-inner-icon="mdi-lock"
                  type="password"
                />
                <v-btn
                  type="button"
                  color="blue lighten-1 text-capitalize"
                  depressed
                  large
                  block
                  dark
                  class="mt-5"
                  style="font-size: 18px"
                  @click="login"
                >
                  Log in
                </v-btn>
                <!-- <v-btn
                  class="mt-5 button_signup"
                  type="button"
                  color="blue lighten-1 text-capitalize"
                  depressed
                  small
                  dark
                  style="font-size: 12px"
                  @click="SignUp"
                >
                  Sign Up
                </v-btn> -->
              </v-form>
            </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
  import * as communicator from '@/router/communicator'

  export default {
    components: {
    },
    mixins: [
    ],
    data () {
      return {
        form: {
          username: "",
          password: "",
          email: ""
        },
      }
    },
    methods: {
      async login() {     
        const res = await communicator.login(this.form)
        if (res.res && res.data.data.res) {
          const url = res.data.data.url
          window.location.href = url
        }
        else{
          alert(res.data.data.message)
        }
      },
      async SignUp(){
        try{
          await this.$router.push('SignUp')
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') {
              throw error;
          }
        }
      },
    },
    async updated () {
    },
    async mounted () {      
    }
  }
</script>

<style lang="sass" scoped>

.form_header
  text-align: center
  font-size: 32px
  font-weight: 500
  margin-bottom: 20px
  #color : rgba(215,235,255)
.vcontainer
  max-width: 450px
  height : 400px
  border: 1px solid rgba(245, 245, 245, 1)
  border-radius : 10px
  background-color: rgba(245, 245, 245, 0.5)

.form_description
  font-size: 18px
  font-weight: 500

.form_contents
  font-size: 17px
  font-weight: 400

.button_signup
  float: right


</style>