<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  import * as communicator from '@/router/communicator'

  export default {
    components: {
    },
    mixins: [
    ],
    data () {
      return {}
    },
    methods: {
      async autoLogin(){
        const res = await communicator.autoLogin()
        if (res.res && res.data.data.res) {
          const url = res.data.data.url
          window.location.href = url
        }
        else {
            await this.$router.push("Login")
        }
      },
    },
    async updated () {
    },
    async mounted () {
      await this.autoLogin()
    }
  }
</script>