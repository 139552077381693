<template>
  <v-app>
    <v-container style="height: 200px;">
    </v-container>
    <v-container style="max-width: 450px;">
      <v-layout align-left row wrap>
        <v-flex xs12>
            <div class="pa-10">
              <h1 style="border:none; text-align: center;" class="mb-3">Sign Up</h1><!--font-size:1.1875rem-->
              <v-form ref="form">
                <v-text-field
                  v-model="form.username"
                  prepend-inner-icon="mdi-account"
                  label="Username"
                ></v-text-field>
                <v-text-field
                  v-model="form.email"
                  prepend-inner-icon="mdi-email"
                  label="Email"
                  :rules="ruleEmail"
                ></v-text-field>
                <v-text-field
                  v-model="form.password"
                  prepend-inner-icon="mdi-lock"
                  type="password"
                  label="Password"
                  :rules="rulePassword"
                >
                </v-text-field>
                <v-text-field
                  v-model="passwordConfirm"
                  prepend-inner-icon="mdi-lock"
                  type="password"
                  label="Password Confirmation"
                  :rules="ruleCofirmPassword"
                >
                </v-text-field>
                <v-text-field
                  v-model="form.company"
                  prepend-inner-icon="mdi-office-building-outline"
                  label="Company"
                  :rules="ruleCompany"
                >
                </v-text-field>

                <v-text-field
                  v-model="form.admin"
                  prepend-inner-icon="mdi-security"
                  label="Admin"
                >
                </v-text-field>
                
                <div style="text-align:right">
                  <v-btn
                    type="button"
                    color="blue lighten-1 text-capitalize"
                    depressed
                    large
                    dark
                    class="mr-3"
                    @click ="signup"
                  >
                    Sign Up
                  </v-btn>
                  <v-btn
                    type="button"
                    color="blue lighten-1 text-capitalize"
                    depressed
                    large
                    dark
                    class="mr-3"
                    @click="cancel"
                  >
                    Cancel
                  </v-btn>
                </div>
              </v-form>
            </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>


<script>
import * as communicator from '@/router/communicator'

  export default {
    data () {
      return {
        passwordConfirm: "",
        passwordError:false,
        form: {
          username: "",
          password: "",
          email:"",
          company:"",
        },
        rulePassword: [
          v => !!v || 'Need Password'
        ],
        ruleCofirmPassword:[
          v => !!v || 'Need Password Confirmation',
          v => v === this.form.password || "Password confirmation doesn't match the password"
        ],
        ruleEmail: [
          v => !!v || 'Need Email',
          v => /.*@.*/.test(v) || 'Invalid Email', // 이메일 형식 검증
        
        ],
        ruleCompany: [
          v => !!v || 'Need Compony'
        ],
      }
    },
    methods: {
      async signup() {
        const validate = this.$refs.form.validate();
        if(validate){
          const res = await communicator.signup(this.form)
          if (res.res && res.data.data.res) {
            alert("Sign Up Success")
          }
          else{
            alert("Sign Up failed")
          }
        }
      },
      async cancel() {     
        try{
          await this.$router.push('Login')
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') {
              throw error;
            }
            else {
              //location.reload()
            }
        }
      },
    },
    components: {
    },
    watch: {   
    },
    mounted() {
      console.log("loading was mounted")
    }
  }
</script>