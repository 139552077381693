import axios from 'axios'
import Cookies from 'js-cookie'

const subDomain = "login"
const domain    = "soc-canvas.com"
const protocol  = (process.env.NODE_ENV === "production") ? "https" : "http"
const host      = (process.env.NODE_ENV === "production") ? `${subDomain}.${domain}/api` : "localhost:8095"

export async function login(form) {
    try {
        const data = await axios.post(`${protocol}://${host}/login`, form, {
            headers: {
                "Content-Type": "application/json"
            },
            params: {
            },
            withCredentials: true
        })
        return {res:true, data:data}
    } catch (err) {
        console.error(err)
        return {res:false, data:err}
    }
}

export async function reissueToken(access_token_res) {
    if(access_token_res.data && (access_token_res.data.message === "ExpiredSignatureError" || access_token_res.data.message === "InvalidTokenError")) {
        try {
            const refresh_token = Cookies.get("refresh-token")
            const data = await axios.post(`${protocol}://${host}/login/reissuetoken`, refresh_token, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                },
                withCredentials: true
            })
            return {res:true, data:data}
        } catch (err) {
            console.error(err)
            return {res:false, data:err}
        }
    }
    return {res:false, data:false}
}


export async function autoLogin() {
    const access_token = Cookies.get("access-token")
    try {
        const data = await axios.post(`${protocol}://${host}/login/autologin`, {}, {
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json"
            },
            params: {
            },
            withCredentials: true
        })

        const reissue_Token = await reissueToken(data)
        if (reissue_Token.res && reissue_Token.data.data.res) {
            return {res:reissue_Token.res, data:reissue_Token.data}
        }
        
        return {res:true, data:data}
    } catch (err) {
        console.error(err)
        return {res:false, data:err}
    }
}

export async function signup(form) {
    try {
        const data = await axios.post(`${protocol}://${host}/login/signup`, form, {
            headers: {
                "Content-Type": "application/json"
            },
            params: {
            },
            withCredentials: true
        })
        return {res:true, data:data}
    } catch (err) {
        console.error(err)
        return {res:false, data:err}
    }
}
